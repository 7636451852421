import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import {
  AccordionData,
  AdFilter,
  Stack,
  Text,
} from "@/components/custom-components";
import {
  LandingFeedAdCard,
  LandingFeedProps,
} from "@/components/templates/LandingAdCard";
import { z } from "zod";
import { landerFeedOrderFilter } from "../../../shared/airtable";
import { getInspirationTabs } from "@/utils/data/feeds.ts";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import {
  AdFeedFilterOption,
  AdLanderSelectedFilters,
  LandingAdSearchParams,
} from "@/hooks/useFilterFeed.tsx";
import { Shuffle } from "@mynaui/icons-react";
import { Button } from "@/components/shadcn/button.tsx";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { featureFlagKeys } from "@/utils/data/featureFlags.ts";

export const Route = createFileRoute("/feeds/inspiration/landing-pages/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): LandingAdSearchParams => {
    const industry = search?.industry as string | undefined;
    const brands = search?.brands as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof landerFeedOrderFilter>
      | undefined;

    return {
      industry,
      brands,
      sideBarOpen,
      orderFilter,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    industry: queryIndustry,
    brands: queryBrands,
    orderFilter,
  } = Route.useSearch();

  const [allData, setAllData] = useState<LandingFeedProps[] | undefined>(
    undefined,
  );
  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);
  const [cursor, setCursor] = useState(1);

  const [selectedFilters, setSelectedFilters] =
    useState<AdLanderSelectedFilters>({
      categories: queryIndustry ? queryIndustry.split(",") : undefined,
      brands: queryBrands ? (queryBrands.split(",") as string[]) : undefined,
    });

  // Get the page categories to be passed to the filter
  const { data: pageIndustry } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });
  const { data: brands } = trpc.getBrands.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (pageIndustry && brands) {
      setFilterOptions([
        {
          title: "Industry",
          counter: 0,
          optionItems: pageIndustry.map((i) => ({
            label: i.name,
            value: false,
          })),
        },
        {
          title: "Brand",
          counter: 0,
          optionItems: (brands ?? []).map((i) => ({
            label: i.brandName,
            logo: i.brandImage,
            value: false,
            id: i.brandId,
          })),
        },
      ]);
    }
  }, [pageIndustry, brands]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => {
      const updatedOptionItems = option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === "Industry" &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === "Brand" &&
            selectedFilters.brands?.includes(item.id ?? "")),
      }));

      return {
        ...option,
        optionItems: updatedOptionItems,
      };
    });

    setFilterOptions(updatedOptions as AdFeedFilterOption[]);
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: { industry?: string; brands?: string }) => {
      const searchParams = new URLSearchParams();

      if (params.industry) searchParams.set("industry", params.industry);
      if (params.brands) searchParams.set("industry", params.brands);

      navigate({
        to: "/feeds/inspiration/landing-pages",
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AccordionData[]) => {
      const selectedIndustry: string[] = [];
      const selectedBrands: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Industry") selectedIndustry.push(item.label);
            if (group.title === "Brand") selectedBrands.push(`${item.id}`);
          }
        });
      });

      setSelectedFilters({
        categories: selectedIndustry.length > 0 ? selectedIndustry : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
      });

      updateQueryString({
        industry:
          selectedIndustry.length > 0 ? selectedIndustry.join(",") : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(",") : undefined,
      });
    },
    [updateQueryString],
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.categories = selectedFilters.categories.join(",");
  }, [selectedFilters]);

  // update cursor whenever selectedFilters change
  useEffect(() => {
    setCursor(1); // Reset cursor to 1 whenever filters change
  }, [selectedFilters]);

  const {
    data: landingPages,
    fetchNextPage,
    refetch,
    isLoading,
    isError,
    isRefetching,
    hasNextPage,
    isFetchingNextPage,
  } = trpc.getAllLandingPages.useInfiniteQuery(
    {
      limit: 20,
      filters: {
        categories: selectedFilters.categories,
        brands: selectedFilters.brands,
        sort: orderFilter as z.infer<typeof landerFeedOrderFilter>,
      },
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    },
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      landingPages &&
      allData &&
      allData.length &&
      landingPages.pages[landingPages.pages.length - 1].results.length !== 0
    ) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, landingPages]);

  useEffect(() => {
    if (!landingPages) return;
    setAllData(() => undefined);
    const records = [] as LandingFeedProps[];
    for (const page of landingPages.pages) {
      records.push(...page.results);
    }
    setAllData(() => records);
  }, [landingPages]);

  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.emailFeeds);
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (flagEnabled !== undefined) {
      setIsResolved(true); // Mark the flag as resolved once its value is known
    }
  }, [flagEnabled]);

  if (!isResolved) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <FeatureTabs
          showSavedCounter
          defaultOrderValue={"Random"}
          tabItems={getInspirationTabs({showEmailFeeds: flagEnabled})}
        />
        <Stack className="gap-3 lg:gap-6">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <Text weight="semibold" size={"xxl"}>
              Get Inspired
            </Text>
            <div className={"flex gap-2"}>
              <div className="lg:hidden">
                <OrderFilterSelect
                  defaultFilter={orderFilter || "Random"}
                  options={["Random", "Newest", "Popular", "Most Requested"]}
                />
              </div>
              <Button
                size={"sm"}
                className="h-8"
                onClick={() => {
                  if (orderFilter !== "Random") {
                    navigate({
                      search: (old) => {
                        return {
                          ...old,
                          orderFilter: "Random",
                        };
                      },
                      params: (old) => {
                        return {
                          ...old,
                        };
                      },
                    });
                  } else {
                    refetch();
                  }
                }}
              >
                <Shuffle className="h-5" />
                Shuffle
              </Button>
            </div>
          </div>
        </Stack>
      </Stack>
      <div
        className={`lg:sticky lg:top-0 lg:z-10 lg:bg-white lg:py-2 flex ${pageIndustry && filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center gap-1.5`}
      >
        {pageIndustry && filterOptions && filterOptions.length > 0 && (
          <AdFilter
            initialOptions={filterOptions}
            onOptionsChange={handleOptionsChange}
          />
        )}

        <div className={"hidden lg:flex"}>
          <OrderFilterSelect
            defaultFilter={orderFilter || "Random"}
            options={["Random", "Newest", "Popular", "Most Requested"]}
          />
        </div>
      </div>
      {isLoading || isRefetching ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div>
          {landingPages && allData && allData.length === 0 ? (
            <div className="flex flex-col justify-center items-center">
              <p className="text-center w-4/5 lg:w-1/2 mb-5">
                Looks like you've gone down a path with no inspiration...this is
                your fault! All we do is win...but really, maybe try a different
                configuration of filters - we got you!
              </p>
              <img src="/giphy.webp" width="480" height="270" alt="" />
            </div>
          ) : (
            <div
              className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"}
            >
              {landingPages &&
                allData &&
                allData.length > 0 &&
                allData.map((card) => (
                  <LandingFeedAdCard key={card.landerId} adData={card} />
                ))}
            </div>
          )}
        </div>
      )}
      <div ref={scrollRef} className="flex justify-center w-full min-h-14 h-14">
        {isFetchingNextPage && <Loader />}
      </div>
    </Stack>
  );
}

export default All;
