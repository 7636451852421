export const INSPIRATION_TABS = [
  {
    name: "Ads",
    link: "/feeds/inspiration",
  },
  {
    name: "Emails",
    link: "/feeds/inspiration/emails",
  },
  {
    name: "Landers",
    link: "/feeds/inspiration/landing-pages",
  },
];

export const REQUESTS_TABS = [
  {
    name: "Ads",
    link: `/feeds/requested`,
  },
  {
    name: "Emails",
    link: `/feeds/requested/emails`,
  },
  {
    name: "Landers",
    link: `/feeds/requested/landing-pages`,
  },
];

export const getInspirationTabs = ({
  showEmailFeeds = false,
}: {
  showEmailFeeds?: boolean;
}) => {
  return showEmailFeeds
    ? INSPIRATION_TABS
    : INSPIRATION_TABS.filter((tab) => tab.name !== "Emails");
};

export const getRequestTabs = ({
  showEmailFeeds = false,
}: {
  showEmailFeeds?: boolean;
}) => {
  return showEmailFeeds
    ? REQUESTS_TABS
    : REQUESTS_TABS.filter((tab) => tab.name !== "Emails");
};
