import { useElementSize } from "@/hooks/useElementSize.tsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { trpc } from "@/utils/trpc.ts";

const BATCH_DELAY = 2000; // Adjust this delay as needed

const useCardLayoutWithImpressions = () => {
  const [squareRef, { width = 0 }] = useElementSize();
  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const { mutate: recordImpressions } = trpc.recordImpressions.useMutation();

  const pendingImpressions = useRef<Set<number>>(new Set());
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const breakpoints = {
    600: 1,
    750: 2,
    900: 4,
    1200: 4,
  };
  const gutterWidth = 24;

  // Calculate the desired card width and column count based on container width
  useEffect(() => {
    if (!width) return;

    let newWidth = 0;
    let cols = 1;

    if (width > 1200) {
      cols = 4;
      newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
    } 
   else if (width > 900) {
      cols = 4;
      newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
    } 
    
    else if (width > 750) {
      cols = 2;
      newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
    } else if (width > 0) {
      cols = 1;
      newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
    }

    setDesiredCardWidth(newWidth);
    setColumns(cols);
  }, [width]);

  // Add post ID to impression batch
  const addPostToBatch = useCallback((postId: number) => {
    if (pendingImpressions.current.size >= 50) return;

    if (!pendingImpressions.current.has(postId)) {
      pendingImpressions.current.add(postId);

      if (!timerRef.current) {
        timerRef.current = setTimeout(sendBatch, BATCH_DELAY);
      }
    }
  }, []);

  // Send impressions batch to the server
  const sendBatch = useCallback(() => {
    if (pendingImpressions.current.size > 0) {
      const postIdsArray = Array.from(pendingImpressions.current);
      recordImpressions(
        { ads: postIdsArray },
        {
          onSuccess: () => pendingImpressions.current.clear(),
          onError: (error) =>
            console.error("Error recording impressions:", error),
        },
      );

      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  }, [recordImpressions]);

  // Cleanup timer on component unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return {
    gutterWidth,
    squareRef,
    desiredCardWidth,
    columns,
    addPostToBatch,
  };
};

export default useCardLayoutWithImpressions;
