import {
  EmailFeedAdCard,
  LandingAdCard,
} from "@/components/templates/LandingAdCard";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Stack, Text } from "@/components/custom-components";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Link as LinkIcon, Pencil, Trash } from "@mynaui/icons-react";
import { BoardDialog, DeleteBoardDialog } from "@/components/templates/Sidebar";
import { useState } from "react";
import { Loader } from "@/components/custom-components/Loader";
import { ArrowLeftIcon } from "lucide-react";
import { Button } from "@/components/shadcn/button";
import showToastNotification from "@/hooks/useShowToast";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/boards/$boardID/emails")({
  component: BoardEmails,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function BoardEmails() {
  const { boardID } = Route.useParams();
  const [editBoardDialogOpen, setEditBoardDialogOpen] =
    useState<boolean>(false);
  const [deleteBoardDialogOpen, setDeleteBoardDialogOpen] =
    useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<
    "All Saved" | "Only Templates"
  >("All Saved");
  const [, copyBoardAdToClipboard] = useCopyToClipboard();
  const {
    data: boardDetails,
    isLoading,
    error,
  } = trpc.getBoardDetails.useQuery(
    {
      boardId: boardID,
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const { data: boardEmails } = trpc.getBoardEmails.useQuery(
    {
      boardId: boardID,
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return (
    <>
      {editBoardDialogOpen && boardDetails && (
        <BoardDialog
          data={{
            name: boardDetails.name,
            id: boardDetails.id,
            description: boardDetails.description || "",
          }}
          open={editBoardDialogOpen}
          onOpenChange={() => setEditBoardDialogOpen(false)}
        />
      )}
      {deleteBoardDialogOpen && boardDetails && (
        <DeleteBoardDialog
          data={{
            name: boardDetails.name,
            id: boardDetails.id,
            description: boardDetails.description || "",
          }}
          open={deleteBoardDialogOpen}
          onOpenChange={() => setDeleteBoardDialogOpen(false)}
        />
      )}
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-8">
          <Stack className="gap-3 lg:gap-2">
            <Link to={"/feeds/boards"} className="flex gap-1.5 w-10">
              <Button variant={"link"} className="p-0" size={"sm"}>
                <ArrowLeftIcon size={"1rem"} />
                All My Collections
              </Button>
            </Link>
            {boardDetails && (
              <div className={"flex justify-between gap-5 items-center"}>
                <Text weight="semibold" size={"xxl"}>
                  {boardDetails?.name}
                </Text>

                <div className="flex gap-1.5 h-8">
                  <Button
                    onClick={() => {
                      copyBoardAdToClipboard(
                        `${window.location.origin}/feeds/boards/${boardDetails?.id}/ads`
                      );
                      showToastNotification("success", { message: "Copied!" });
                    }}
                    className="hidden lg:flex gap-1.5"
                    variant={"outline"}
                    size={"sm"}
                  >
                    <LinkIcon className="w-5 h-5" />
                    Share
                  </Button>
                  <Button
                    onClick={() => {
                      copyBoardAdToClipboard(
                        `${window.location.origin}/feeds/boards/${boardDetails?.id}/ads`
                      );
                      showToastNotification("success", { message: "Copied!" });
                    }}
                    className="lg:hidden flex gap-1.5"
                    variant={"outline"}
                    size={"iconMid"}
                  >
                    <LinkIcon className="w-5 h-5" />
                  </Button>
                  <Button
                    onClick={() => setEditBoardDialogOpen(true)}
                    className="gap-1.5"
                    variant={"outline"}
                    size={"iconMid"}
                  >
                    <Pencil className="w-5 h-5" />
                  </Button>
                  <Button
                    onClick={() => setDeleteBoardDialogOpen(true)}
                    className="gap-1.5"
                    variant={"outline"}
                    size={"iconMid"}
                  >
                    <Trash className="w-5 h-5" />
                  </Button>
                </div>
              </div>
            )}
            {boardDetails && (
              <div className={`flex gap-1 items-center justify-between`}>
                <div className={`flex gap-1 items-center`}>
                  <Text
                    className="text-thememutedforeground w-fit"
                    size={"base"}
                    weight={"normal"}
                  >
                    {boardDetails?.description}
                  </Text>
                </div>
                <Text
                  className="text-thememutedforeground w-fit text-nowrap hidden lg:flex"
                  size={"base"}
                  weight={"normal"}
                >
                  {(boardDetails?.emailTemplates ?? []).length}
                  {(boardDetails?.emailTemplates ?? []).length == 1
                    ? " Item"
                    : " Items"}
                </Text>
              </div>
            )}
          </Stack>

          <div className="flex items-center gap-6">
            <FeatureTabs
              showPro={false}
              tabItems={[
                {
                  name: "Ads",
                  link: `/feeds/boards/${boardID}/ads`,
                },
                {
                  name: "Landers",
                  link: `/feeds/boards/${boardID}/ad-landers`,
                },
                {
                  name: "Emails",
                  link: `/feeds/boards/${boardID}/emails`,
                },
              ]}
            />
            <Select
              value={selectValue}
              onValueChange={(value: "All Saved" | "Only Templates") =>
                setSelectValue(value)
              }
            >
              <SelectTrigger className="w-33 h-8 max-h-8">
                <SelectValue placeholder="Ad Type" className="rounded-md" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {["All Saved", "Only Templates"].map((item, idx) => (
                    <SelectItem key={idx} value={item} className="py-1.5">
                      {item}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </Stack>

        {isLoading ? (
          <div
            className={
              "flex justify-center items-center w-full h-screen m-auto"
            }
          >
            <Loader />
          </div>
        ) : error ? (
          <ErrorDisplay />
        ) : (
          <div className={""}>
            {boardEmails && (
              <>
                {(selectValue == "All Saved"
                  ? (boardEmails.emailInspirations ?? []).filter(
                      (item, index, self) =>
                        index === self.findIndex((obj) => obj.id === item.id)
                    ).length == 0
                  : (boardEmails.emailInspirations ?? [])
                      .filter(
                        (item, index, self) =>
                          index === self.findIndex((obj) => obj.id === item.id)
                      )
                      .filter((data) => data.template).length == 0) &&
                (boardEmails.emailTemplates ?? []).length == 0 ? (
                  <div className={"lg:h-96 flex justify-center items-center"}>
                    <p>No email added to this board</p>
                  </div>
                ) : (
                  <div
                    className={
                      "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2.5"
                    }
                  >
                    {boardEmails.emailTemplates.map((card) => (
                      <LandingAdCard
                        key={card["Email ID"]}
                        adData={{
                          Screenshot: card["Email Screenshot"],
                          ID: card["Email ID"].toString(),
                          created: card.Created,
                          category: card.Category,
                          isSaved: card.isSaved,
                        }}
                        showTemplateButton
                        type={"email"}
                      />
                    ))}
                    {boardEmails.emailInspirations
                      .filter(
                        (item, index, self) =>
                          index === self.findIndex((obj) => obj.id === item.id)
                      )
                      .map((card) =>
                        selectValue == "All Saved" && !card.template ? (
                          <EmailFeedAdCard key={card.id} adData={card} />
                        ) : card.template ? (
                          <LandingAdCard
                            key={card.template["Email ID"]}
                            adData={{
                              Screenshot: card.template["Email Screenshot"],
                              ID: card.template["Email ID"].toString(),
                              created: card.template.Created,
                              category: card.template.Category,
                              isSaved: card.template.isSaved,
                            }}
                            showTemplateButton
                            type={"email"}
                          />
                        ) : (
                          <></>
                        )
                      )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Stack>
    </>
  );
}
