import { Link } from "@tanstack/react-router";
import { Text } from "@/components/custom-components";
import { z } from "zod";
import {
  landerFeedOrderFilter,
  orderFilter as OrderFilterTypes,
} from "../../../../shared/airtable";
import { Tabs, TabsList, TabsTrigger } from "@/components/shadcn/tabs.tsx";

interface InspirationTabsProps {
  pages: Array<{ name: string; link: string }>;
  defaultOrderValue?:
    | z.infer<typeof landerFeedOrderFilter>
    | z.infer<typeof OrderFilterTypes>;
}

export default function InspirationTabs({
  pages,
  defaultOrderValue,
}: InspirationTabsProps) {
  return (
    <Tabs className="w-fit" value={location.pathname}>
      <TabsList className="rounded-full">
        {pages &&
          pages.length > 0 &&
          pages.map((item, index) => {
            return (
              <TabsTrigger key={index} value={item.link} className="rounded-full">
                <Link
                  to={item.link}
                  search={(d) => ({
                    ...d,
                    cacheBuster: Math.random(),
                    orderFilter: defaultOrderValue,
                    sideBarOpen: false,
                  })}
                  className="flex items-center gap-1.5"
                  activeProps={{
                    className: "bg-white text-themeforeground",
                  }}
                  activeOptions={{
                    exact: true,
                    includeSearch: false,
                  }}
                  key={item.name}
                >
                  <Text
                    className={"text-sm leading-5"}
                    weight={"medium"}
                    data-testid={item.name}
                  >
                    {item.name}
                  </Text>
                </Link>
              </TabsTrigger>
            );
          })}
      </TabsList>
    </Tabs>
  );
}
