import { useEffect, useRef } from "react";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { useOnClickOutside } from "@/hooks/useClickOutside.tsx";
import { z } from "zod";
import { orderFilter } from "../../../shared/airtable";
import {
  Sidebar,
  SidebarButton,
} from "@/components/templates/Sidebar/index.tsx";
import { ManageAccountV2 } from "@/components/templates/AppHeader/index.tsx";

type LibraryProps = {
  sideBarOpen?: boolean | undefined;
  sidebarCollapsed?: boolean | undefined;
  orderFilter?: z.infer<typeof orderFilter> | undefined;
  cacheBuster?: number | undefined;
};

export const Route = createFileRoute("/share")({
  component: Share,
  validateSearch: (search: Record<string, unknown>): LibraryProps => {
    return {
      sideBarOpen: search.sideBarOpen as boolean | undefined,
      sidebarCollapsed: search.sidebarCollapsed as boolean | undefined,
      orderFilter: search.orderFilter as
        | z.infer<typeof orderFilter>
        | undefined,
      cacheBuster: search.cacheBuster as number | undefined,
    };
  },
});

function Share() {
  const { sideBarOpen, sidebarCollapsed } = Route.useSearch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const handleClickOutside = () => {
    if (sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sideBarOpen: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  };
  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    if (!sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sidebarCollapsed: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  }, [sideBarOpen, navigate]);

  return (
    <>
      <div className={"relative flex h-screen overflow-y-hidden"}>
        <div
          className={`absolute lg:ml-0 lg:static ${sidebarCollapsed ? "lg:w-[3rem]" : "lg:w-56"} w-full flex-sidebar shrink-0 z-30 transition-all duration-500 ${sideBarOpen ? "ml-0" : "-ml-[100vw]"}`}
        >
          <div ref={ref}>
            <Sidebar sidebarCollapsed={sidebarCollapsed} isPublicView={true} />
          </div>
        </div>
        <div className={"w-full flex-auto overflow-x-hidden overflow-y-auto"}>
          <div
            className={`${sidebarCollapsed ? "max-w-[2000px]" : "max-w-pagemax"}`}
          >
            <div className="lg:hidden flex w-full h-12 bg-slate-50 items-center justify-between p-3">
              <img
                alt={"Creative OS"}
                src={"/images/cos-logo-nobg.png"}
                className={"h-6"}
              />
              <div className="items-center flex gap-3">
                <ManageAccountV2 showName={false} />
                <SidebarButton sideBarOpen={sideBarOpen} />
              </div>
            </div>
            {/*<button*/}
            {/*  className={`md:hidden w-auto px-16 py-2 mx-10 mt-2 text-sm font-semibold text-white rounded-full ${sidebarState ? "bg-red-500" : "bg-blue-500"}`}*/}
            {/*  onClick={toggleSidebar}*/}
            {/*>*/}
            {/*  Debug Toggle Box*/}
            {/*</button>*/}

            <div className="p-3 lg:p-6">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
