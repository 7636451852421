import { createFileRoute } from "@tanstack/react-router";

import { Stack } from "@/components/custom-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/shadcn/breadcrumb.tsx";
import { Textarea } from "@/components/shadcn/textarea.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/shadcn/table.tsx";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/shadcn/form.tsx";
import { syllable } from "syllable";
import { useState } from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";

type SearchParams = {
  sideBarOpen?: boolean;
};

const reabilityscores: ScoreType[] = [
  {
    score: "90 - 100",
    max: 100,
    min: 90,
    grade: "5th Grade",
    summary: "Very easy to read",
  },
  {
    score: "80 - 90",
    max: 90,
    min: 80,
    grade: "6th Grade",
    summary: "Easy to read",
  },
  {
    score: "70 - 80",
    max: 80,
    min: 70,
    grade: "7th Grade (U.S Average Reading Level)",
    summary: "Fairly easy to read",
  },
  {
    score: "60 - 70",
    max: 70,
    min: 60,
    grade: "8th - 9th Grade",
    summary: "Plain English",
  },
  {
    score: "50 - 60",
    max: 60,
    min: 50,
    grade: "10th - 12th Grade",
    summary: "Fairly difficult to read",
  },
  {
    score: "30 - 50",
    max: 50,
    min: 30,
    grade: "College",
    summary: "Difficult to read",
  },
  {
    score: "0 - 30",
    max: 30,
    min: 0,
    grade: "College Graduate",
    summary: "Very difficult to read",
  },
];

export const Route = createFileRoute("/feeds/creative-toolkit/copy-grader/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const [flesch, setFlesch] = useState<{
    totalWords: number;
    totalSentences: number;
    totalSyllables: number;
    totalCharacters: number;
    score: number;
  }>({
    totalWords: 0,
    totalSentences: 0,
    totalSyllables: 0,
    totalCharacters: 0,
    score: 0,
  });

  const formSchema = z.object({
    copyText: z.string({ required_error: "Copy text is required" }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      copyText: "",
    },
  });

  function getTotalWords(sentence: string): number {
    if (!sentence.trim()) return 0; // Handle empty or whitespace-only strings
    return sentence.trim().split(/\s+/).length;
  }

  function getTotalSentences(text: string): number {
    // Split text into sentences using regex and filter out empty entries
    const sentences = text
      .split(/[.!?]+/)
      .filter((sentence) => sentence.trim() !== "");
    return sentences.length;
  }

  function getTotalCharacters(text: string): number {
    return text.length;
  }

  function getSummary(score: number): string {
    if (score > 100) {
      // Use the 90-100 summary for scores above 100
      const aboveMax = reabilityscores.find((range) => range.max === 100);
      return aboveMax ? aboveMax.summary : "Very easy to read";
    }

    if (score <= 0) {
      // Use the 0 summary for scores 0 or below
      const zeroOrLess = reabilityscores.find((range) => range.max === 0);
      return zeroOrLess ? zeroOrLess.summary : "No Score";
    }

    const match = reabilityscores.find(
      (range) => score >= range.min && score <= range.max,
    );
    return match ? match.summary : "Score out of range";
  }

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    const totalWords = getTotalWords(values.copyText);
    const totalSentences = getTotalSentences(values.copyText);
    const totalCharacters = getTotalCharacters(values.copyText);
    const totalSyllables = syllable(values.copyText);

    const fleschScore =
      206.835 -
      1.015 * (totalWords / totalSentences) -
      84.6 * (totalSyllables / totalWords);

    setFlesch({
      totalWords,
      totalSentences,
      totalCharacters,
      totalSyllables,
      score: parseFloat(fleschScore.toFixed(1)),
    });
  };

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-6">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/feeds/creative-toolkit">
                  Toolkit
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Copy Grader</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <div className={""}>
              <h1 className={"text-2xl font-semibold"}>
                Run your Copy through our Grader
              </h1>
              <p
                className={
                  "text-sm lg:text-base text-thememutedforeground mt-2"
                }
              >
                This calculator evaluates the readability of your ad copy using
                the Flesch Reading Ease Scale.
              </p>
            </div>
          </div>
        </Stack>
      </Stack>
      <div>
        <div className={"flex flex-col-reverse lg:flex-row gap-6"}>
          <div className={"lg:w-2/3 flex flex-col gap-6"}>
            <div>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)} className={""}>
                  <FormField
                    control={form.control}
                    name="copyText"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Textarea
                            rows={4}
                            placeholder={"Enter Your Copy"}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <Button
                    disabled={form.getValues("copyText") === ""}
                    className={"mt-3"}
                  >
                    Submit
                  </Button>
                </form>
              </Form>
            </div>
            <div>
              <h4 className={"text-sm font-medium"}>
                Flesch-Kincaid readability scores
              </h4>
              <div>
                {reabilityscores && (
                  <ReadabilityScore
                    data={reabilityscores}
                    fleschScore={flesch.score}
                  />
                )}
              </div>
            </div>
            <div>
              <h4 className={"text-sm font-medium"}>
                What is a Flesch Reading Ease Score?
              </h4>
              <p
                className={
                  "text-thememutedforeground font-light mt-1.5 leading-snug"
                }
              >
                Most of the time, a{" "}
                <a target={"_blank"} href={"#"}>
                  readability score
                </a>{" "}
                helps you understand how easy it is to read a piece of content
                or a passage. It indicates the educational level required to
                read the text without difficulty. The Flesch reading ease test
                score indicates the understandability of a passage with a number
                that ranges from 0 to 100. It shows how difficult it is for an
                average adult to understand the content. Higher scores mean the
                content is easier to read and understand. The Flesch reading
                score is measured on a scale of 0 to 100, with 100 being the
                easiest to read. More so, a lower score means the passage might
                be hard to understand and would be suitable for only
                professionals. Therefore, if a context scores 70 and above, it
                is said to have a good Flesch reading score and is fairly easy
                to read.
              </p>
            </div>
          </div>
          <div className={"lg:w-1/3"}>
            <div className={"border rounded"}>
              <div className={"p-3 flex flex-col items-center"}>
                <h5 className={"font-medium text-center text-sm"}>
                  Flesch Reading Ease Score
                </h5>
              </div>
              <div className={"flex justify-center"}>
                <div className={"py-6 px-8 relative"}>
                  <SemiCircleProgressBar
                    strokeWidth={6}
                    percentage={flesch.score}
                    diameter={300}
                    stroke={
                      flesch.score >= 80
                        ? "#0CDB71"
                        : flesch.score >= 60 && flesch.score <= 79
                          ? "#F7A940"
                          : flesch.score >= 50 && flesch.score <= 59
                            ? "#FF0054"
                            : "#CC2C69"
                    }
                  />
                  <div
                    className={
                      "absolute bottom-5 left-1/2 transform -translate-x-1/2 text-center"
                    }
                  >
                    <h4 className={"text-4xl font-bold mb-2"}>
                      {flesch.score < 0 ? "-" : flesch.score}
                    </h4>
                    <p className={"font-medium"}>{getSummary(flesch.score)}</p>
                  </div>
                </div>
              </div>
              <div className={"grid grid-cols-2"}>
                {[
                  { title: "Characters", value: flesch.totalCharacters },
                  { title: "Words", value: flesch.totalWords },
                  { title: "Sentences", value: flesch.totalSentences },
                  { title: "Syllables", value: flesch.totalSyllables },
                ].map((item) => (
                  <div key={item.title} className={"flex flex-col text-center"}>
                    <p
                      className={"font-light text-sm text-thememutedforeground"}
                    >
                      {item.title}
                    </p>
                    <p className={"text-lg font-bold"}>{item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Stack>
  );
}

type ScoreType = {
  score: string;
  max: number;
  min: number;
  grade: string;
  summary: string;
};

function ReadabilityScore({
  data,
  fleschScore,
}: {
  data: ScoreType[];
  fleschScore: number;
}) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-fit">Score</TableHead>
          <TableHead className="w-fit">Reading Grade Level</TableHead>
          <TableHead>Summary</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((data) => {
          let rowBackgroundColor: string = "";

          // Highlight only the row with the matching min score range
          if (fleschScore < 0 && data.min === 0) {
            rowBackgroundColor = "bg-maroon-100";
          } else if (
            (fleschScore >= 90 && data.min === 90) ||
            (fleschScore >= 80 && fleschScore <= 89 && data.min === 80)
          ) {
            rowBackgroundColor = "bg-green-50"; // Green for 80 and above
          } else if (
            (fleschScore >= 70 && fleschScore <= 79 && data.min === 70) ||
            (fleschScore >= 60 && fleschScore <= 69 && data.min === 60)
          ) {
            rowBackgroundColor = "bg-yellow-50"; // Yellow for 60-79
          } else if (
            (fleschScore >= 30 && fleschScore <= 49 && data.min === 30) ||
            (fleschScore >= 50 && fleschScore <= 59 && data.min === 50)
          ) {
            rowBackgroundColor = "bg-maroon-50"; // Maroon for 30-59
          } else if (fleschScore <= 29 && fleschScore !== 0 && data.min === 0) {
            rowBackgroundColor = "bg-maroon-100"; // Dark Maroon for 0-29
          }

          return (
            <TableRow
              key={data.score}
              className={`${rowBackgroundColor} transition-none`}
            >
              <TableCell className="font-medium w-[5rem] md:w-auto">
                {data.score}
              </TableCell>
              <TableCell className={"text-thememutedforeground"}>
                {data.grade}
              </TableCell>
              <TableCell className={"text-thememutedforeground"}>
                {data.summary}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
