import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import { Ad } from "@/components/templates/DiscoverGridView";
import { trpc } from "@/utils/trpc.ts";
import {
  CalendarUp,
  InfoCircle,
  Like,
  Link as LinkIcon,
  TrendingDown,
  TrendingUp,
  X,
} from "@mynaui/icons-react";
import { Link } from "@tanstack/react-router";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { useState } from "react";
import { ManuallySaveAdToBoard } from "@/components/templates/AdCard";
import { Clock7 } from "lucide-react";
import { cn } from "@/lib/utils.ts";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { Ad as AdCreativeDetails } from "../../../server/types";

const InspirationDetailsMediaSection = ({
  adCreativeDetails,
  data,
}: {
  adCreativeDetails?: AdCreativeDetails | null;
  data: Ad;
}) => (
  <div className=" flex flex-col my-12 mx-8 items-center">
    <div
      className={
        "max-w-[23.25rem] w-full border border-themeborder bg-white rounded"
      }
    >
      <div>
        <div className={"p-3"}>
          <div className={"flex gap-5 justify-between"}>
            <div className={`flex gap-1 items-center`}>
              {data.brandImage && data.brandId && (
                <Link
                  to={`/feeds/brands/$brandID`}
                  params={{ brandID: data.brandId }}
                >
                  <img
                    src={data.brandImage}
                    alt={data.brandName || "Brand name"}
                    className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                  ></img>
                </Link>
              )}
              <div>
                {data.brandName && data.brandId && (
                  <Link
                    to={`/feeds/brands/$brandID`}
                    params={{ brandID: data.brandId }}
                  >
                    <h4 className={`truncate font-[500] text-sm`}>
                      {data.brandName}
                    </h4>
                  </Link>
                )}
              </div>
            </div>
          </div>
          {adCreativeDetails && (
            <div>
              <p className={"text-xs text-thememutedforeground truncate"}>
                {adCreativeDetails.description}
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        {data.imageUrl && (
          <img
            className="w-full"
            alt="ad display"
            loading="lazy"
            src={data.imageUrl}
          />
        )}
        {data.videoUrl && (
          <video
            className="w-full z-10"
            muted
            loop
            // autoPlay // Ensure video autoplays
            playsInline // For mobile devices (iOS in particular)
            src={data.videoUrl}
            onMouseEnter={(e) => {
              (e.currentTarget as HTMLVideoElement).play();
            }}
            onMouseLeave={(e) => {
              (e.currentTarget as HTMLVideoElement).pause();
            }}
          ></video>
        )}
      </div>
      {adCreativeDetails && (
        <div className={"px-3 pt-2 pb-3 flex gap-2"}>
          <div className={"flex-1 max-w-[80%]"}>
            <h3 className={"text-sm font-medium w-full truncate"}>
              {adCreativeDetails.headline}
            </h3>
            <div className="text-xs md:text-sm lg:text-base text-thememutedforeground line-clamp-3">
              {
                adCreativeDetails?.text
                // ?.split("\n")
                // .map((line, index) => <p key={index}>{line}</p>)
              }
            </div>
          </div>
          <div className={"w-fit"}>
            {adCreativeDetails.landingPageUrl && (
              <a
                href={adCreativeDetails.landingPageUrl}
                target={"_blank"}
                className={cn(
                  buttonVariants({
                    variant: "secondary",
                    size: "sm",
                  }),
                )}
              >
                CTA
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default function AdInspirationDetails({
  open,
  onOpenChange,
  data,
  IsPublic,
}: {
  open: boolean;
  onOpenChange: () => void;
  data: Ad;
  IsPublic: boolean;
}) {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();

  const { data: adCreativeDetails } = trpc.getAdCreativeDetails.useQuery(
    { adId: data.id },
    {
      enabled: open,
    },
  );

  const { data: numberOfActiveAdsForBrand } =
    trpc.getNumberOfActiveAdsForBrand.useQuery(
      { brandId: data.brandId || "" },
      {
        enabled: open,
      },
    );

  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  const [adRequested, setAdRequested] = useState<boolean>(false);

  const { mutateAsync: requestAdTemplate } =
    trpc.requestAdTemplateRpc.useMutation();

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent
        className={"h-[85vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
        onInteractOutside={(e) => e.preventDefault()}
      >
        {accessPromptDialogOpen && (
          <FreeAccessPrompt
            open={accessPromptDialogOpen}
            onOpenChange={() => setAccessPromptDialogOpen(false)}
          />
        )}

        <ScrollArea
          className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
        >
          <InspirationDetailsMediaSection
            adCreativeDetails={adCreativeDetails}
            data={data}
          />
        </ScrollArea>
        <div className="hidden lg:flex">
          <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
            <div className={"flex justify-end"}>
              <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                <X />
              </span>
            </div>

            <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6 w-full">
              <div className={"flex gap-5 justify-between"}>
                <div className={`flex gap-1 items-center`}>
                  {data.brandImage && data.brandId && (
                    <Link
                      to={`/feeds/brands/$brandID`}
                      params={{ brandID: data.brandId }}
                    >
                      <img
                        src={data.brandImage}
                        alt={data.brandName || "Brand name"}
                        className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                      ></img>
                    </Link>
                  )}
                  <div>
                    {data.brandName && data.brandId && (
                      <Link
                        to={`/feeds/brands/$brandID`}
                        params={{ brandID: data.brandId }}
                      >
                        <h4 className={`truncate font-[500] text-sm`}>
                          {data.brandName}
                        </h4>
                      </Link>
                    )}
                    <p className={"text-xs text-thememutedforeground"}>
                      Currently running{" "}
                      {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
                    </p>
                  </div>
                </div>

                <div className={"flex gap-2"}>
                  {!IsPublic && data && (
                    <ManuallySaveAdToBoard
                      open={saveOpen}
                      onOpenChange={setSaveOpen}
                      data={data}
                      variant={"outline"}
                    />
                  )}

                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/${data.id}`,
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>

              <div>
                <p className={"font-semibold text-xl"}>
                  <span>{data.brandName}</span> Ad
                </p>
                <div className={"flex gap-4 text-thememutedforeground text-sm"}>
                  <div>
                    <span className={"flex items-center gap-2"}>
                      {data.isActiveSinceLastScrape ? (
                        <>
                          <span>
                            <TrendingUp className={"text-green-500"} />
                          </span>
                          <span>Active for {data.adRunningDays} Days</span>
                        </>
                      ) : (
                        <>
                          <span>
                            <TrendingDown className={"text-amber-500"} />
                          </span>
                          <span>Inactive for {data.inactiveTime} Days</span>
                        </>
                      )}
                    </span>
                  </div>
                  <div className={"flex items-center gap-1"}>
                    <span>
                      <Like className={"w-4.5"} />
                    </span>
                    <span>{data?.requestCount || 0} Requests</span>
                  </div>
                </div>
              </div>

              <div
                className={`grid grid-cols-${!data.videoUrl ? "2" : "1"} gap-3`}
              >
                {!data.videoUrl && (
                  <Button
                    onClick={
                      !authUser
                        ? () => setAccessPromptDialogOpen(true)
                        : () => {
                            setAdRequested(true);
                            requestAdTemplate({
                              adId: data.id,
                            }).then(() => {
                              showToastNotification("success", {
                                message: `Ad Template has been requested!`,
                              });
                              setTimeout(() => {
                                setAdRequested(false);
                              }, 5000);
                            });
                          }
                    }
                  >
                    {adRequested ? "Requested!" : "Request Template"}
                  </Button>
                )}
                {adCreativeDetails?.landingPageUrl && (
                  <a
                    href={adCreativeDetails?.landingPageUrl}
                    target={"_blank"}
                    className={cn(buttonVariants({ variant: "secondary" }))}
                  >
                    View Landing Page
                  </a>
                )}
              </div>
              {adCreativeDetails && (
                <div className={"flex flex-col gap-4 text-sm"}>
                  <div className={"flex justify-between items-center gap-1"}>
                    <div className={"flex items-center gap-1"}>
                      {adCreativeDetails.rating > 4 && <span>🔥</span>}
                      <span className={"font-medium"}>
                        {{
                          5: "Excellent",
                          4: "Great",
                          3: "Good",
                          2: "Average",
                          1: "Low",
                        }[adCreativeDetails.rating] || ""}{" "}
                        Performance
                      </span>

                      <TooltipProvider>
                        <Tooltip open={openTooltip}>
                          <TooltipTrigger>
                            <span
                              className={"text-thememutedforeground"}
                              onClick={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseEnter={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseLeave={() => {
                                setOpenTooltip(false);
                              }}
                            >
                              <InfoCircle size={"1.2rem"} />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent className="max-w-sm">
                            <p>
                              Performance Score is an estimate calculated using
                              benchmarks like Ad Longevity, Ad Spend, and
                              performance metrics
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    {adCreativeDetails?.rating && (
                      <div className={"flex gap-1"}>
                        {[...Array(5)].map((_, index: number) => (
                          <div
                            key={index}
                            className={`rounded-full ${index < adCreativeDetails.rating ? "bg-themedestructive" : "bg-purple-100"} w-8 h-2`}
                          ></div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={"flex justify-between items-center gap-1"}>
                    <div className={"flex items-center gap-1.5"}>
                      <span>
                        <CalendarUp size={"1.2rem"} />
                      </span>
                      <span className={"font-medium"}>Published</span>
                    </div>
                    <div>
                      <span className={"text-thememutedforeground"}>
                        {adCreativeDetails.publishedDate &&
                        !isNaN(
                          new Date(adCreativeDetails.publishedDate).getTime(),
                        )
                          ? new Date(
                              adCreativeDetails.publishedDate,
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className={"flex justify-between items-center gap-1"}>
                    <div className={"flex items-center gap-1.5"}>
                      <span>
                        <Clock7 size={"1.2rem"} />
                      </span>
                      <span className={"font-medium"}>Run Time</span>
                    </div>
                    <div>
                      <span className={"text-thememutedforeground"}>
                        {data.isActiveSinceLastScrape ? (
                          <>{data.adRunningDays} Days and still running</>
                        ) : (
                          <>Inactive for {data.inactiveTime} Days</>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ScrollArea className="flex lg:hidden h-full">
          <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
            <div className={"flex justify-end"}>
              <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                <X />
              </span>
            </div>

            <div className="flex flex-col  px-2 lg:px-8 py-3 pt-0 lg:py-12 lg:pt-4 gap-3 lg:gap-6">
              <div className={"flex gap-5 justify-between"}>
                <div className={`flex gap-1 items-center`}>
                  {data.brandImage && data.brandId && (
                    <Link
                      to={`/feeds/brands/$brandID`}
                      params={{ brandID: data.brandId }}
                    >
                      <img
                        src={data.brandImage}
                        alt={data.brandName || "Brand name"}
                        className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
                      ></img>
                    </Link>
                  )}
                  <div>
                    {data.brandName && data.brandId && (
                      <Link
                        to={`/feeds/brands/$brandID`}
                        params={{ brandID: data.brandId }}
                      >
                        <h4 className={`truncate font-[500] text-sm`}>
                          {data.brandName}
                        </h4>
                      </Link>
                    )}
                    <p className={"text-xs text-thememutedforeground"}>
                      Currently running{" "}
                      {numberOfActiveAdsForBrand?.numberOfActiveAds || 0} ads
                    </p>
                  </div>
                </div>

                <div className={"flex gap-2"}>
                  {!IsPublic && data && (
                    <ManuallySaveAdToBoard
                      open={saveOpen}
                      onOpenChange={setSaveOpen}
                      data={data}
                      variant={"outline"}
                    />
                  )}

                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          onClick={() => {
                            copyToClipboard(
                              `${window.location.origin}/share/inspiration/${data.id}`,
                            );
                            showToastNotification("success", {
                              message: "Copied!",
                            });
                          }}
                          variant={"outline"}
                          size={"icon"}
                        >
                          <LinkIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Share a public link</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>

              <div>
                <p className={"font-semibold text-xl"}>
                  <span>{data.brandName}</span> Ad
                </p>
                <div className={"flex gap-4 text-thememutedforeground text-sm"}>
                  <div>
                    <span className={"flex items-center gap-2"}>
                      {data.isActiveSinceLastScrape ? (
                        <>
                          <span>
                            <TrendingUp className={"text-green-500"} />
                          </span>
                          <span>Active for {data.adRunningDays} Days</span>
                        </>
                      ) : (
                        <>
                          <span>
                            <TrendingDown className={"text-amber-500"} />
                          </span>
                          <span>Inactive for {data.inactiveTime} Days</span>
                        </>
                      )}
                    </span>
                  </div>
                  <div className={"flex items-center gap-1"}>
                    <span>
                      <Like className={"w-4.5"} />
                    </span>
                    <span>
                      {adCreativeDetails?.numberOfRequests || 0} Requests
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`grid grid-cols-${!data.videoUrl ? "2" : "1"} gap-3`}
              >
                {!data.videoUrl && (
                  <Button
                    onClick={
                      !authUser
                        ? () => setAccessPromptDialogOpen(true)
                        : () => {
                            setAdRequested(true);
                            requestAdTemplate({
                              adId: data.id,
                            }).then(() => {
                              showToastNotification("success", {
                                message: `Ad Template has been requested!`,
                              });
                              setTimeout(() => {
                                setAdRequested(false);
                              }, 5000);
                            });
                          }
                    }
                  >
                    {adRequested ? "Requested!" : "Request Template"}
                  </Button>
                )}
                {adCreativeDetails?.landingPageUrl && (
                  <a
                    href={adCreativeDetails?.landingPageUrl}
                    target={"_blank"}
                    className={cn(buttonVariants({ variant: "secondary" }))}
                  >
                    View Landing Page
                  </a>
                )}
              </div>
              {adCreativeDetails && (
                <div className={"flex flex-col gap-4 text-sm"}>
                  <div className={"flex justify-between items-center gap-1"}>
                    <div className={"flex items-center gap-1"}>
                      {adCreativeDetails.rating > 4 && <span>🔥</span>}
                      <span className={"font-medium"}>
                        {{
                          5: "Excellent",
                          4: "Great",
                          3: "Good",
                          2: "Average",
                          1: "Low",
                        }[adCreativeDetails.rating] || ""}{" "}
                        Performance
                      </span>

                      <TooltipProvider>
                        <Tooltip open={openTooltip}>
                          <TooltipTrigger>
                            <span
                              className={"text-thememutedforeground"}
                              onClick={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseEnter={() => {
                                setOpenTooltip(!openTooltip);
                              }}
                              onMouseLeave={() => {
                                setOpenTooltip(false);
                              }}
                            >
                              <InfoCircle size={"1.2rem"} />
                            </span>
                          </TooltipTrigger>
                          <TooltipContent className="max-w-sm">
                            <p>
                              Performance Score is an estimate calculated using
                              benchmarks like Ad Longevity, Ad Spend, and
                              performance metrics
                            </p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    {adCreativeDetails?.rating && (
                      <div className={"flex gap-1"}>
                        {[...Array(5)].map((_, index: number) => (
                          <div
                            key={index}
                            className={`rounded-full ${index < adCreativeDetails.rating ? "bg-themedestructive" : "bg-purple-100"} w-8 h-2`}
                          ></div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={"flex justify-between items-center gap-1"}>
                    <div className={"flex items-center gap-1.5"}>
                      <span>
                        <CalendarUp size={"1.2rem"} />
                      </span>
                      <span className={"font-medium"}>Published</span>
                    </div>
                    <div>
                      <span className={"text-thememutedforeground"}>
                        {adCreativeDetails.publishedDate &&
                        !isNaN(
                          new Date(adCreativeDetails.publishedDate).getTime(),
                        )
                          ? new Date(
                              adCreativeDetails.publishedDate,
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className={"flex justify-between items-center gap-1"}>
                    <div className={"flex items-center gap-1.5"}>
                      <span>
                        <Clock7 size={"1.2rem"} />
                      </span>
                      <span className={"font-medium"}>Run Time</span>
                    </div>
                    <div>
                      <span className={"text-thememutedforeground"}>
                        {data.isActiveSinceLastScrape ? (
                          <>{data.adRunningDays} Days and still running</>
                        ) : (
                          <>Inactive for {data.inactiveTime} Days</>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full min-h-[65vh]"}
          >
            <InspirationDetailsMediaSection
              adCreativeDetails={adCreativeDetails}
              data={data}
            />{" "}
          </ScrollArea>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
}
