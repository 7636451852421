import { createFileRoute } from "@tanstack/react-router";
import { Tags } from "../../../shared/airtable";
import { AdGridView } from "@/components/templates/AdGridView";
import { Stack, Text } from "@/components/custom-components";

type SearchParams = {
  Tags?: Tags;
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/templates/$adID")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const tag = search?.Tags as Tags | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    return {
      Tags: tag,
      sideBarOpen,
    };
  },
});

function All() {
  const { Tags } = Route.useSearch();
  const { adID } = Route.useParams();
  return (
    <div className={"relative h-[97vh]"}>
      <Stack className="!mb-6 gap-3">
        <div
          className={"flex justify-between lg:justify-start gap-5 items-center"}
        >
          <Text weight="semibold" size={"xxl"}>
            Ad Templates
          </Text>
        </div>
        <Text
          className="text-thememutedforeground w-fit"
          size={"base"}
          weight={"normal"}
        >
          Somebody shared a template with you!
        </Text>
      </Stack>
      <AdGridView
          Filter={{
            Expert: undefined,
            Tags: Tags ? [Tags] : undefined,
            Ready: true,
            cursor: undefined,
            limit: 20,
            sortingOptions: undefined,
            adID: adID,
          }}
        />
    </div>
  );
}
