import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { Stack } from "@/components/custom-components";
import { Badge } from "@/components/shadcn/badge.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/creative-toolkit/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const navigate = useNavigate();

  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="gap-3 lg:gap-8">
        <Stack className="gap-3 lg:gap-6">
          <div
            className={
              "flex justify-between lg:justify-start gap-5 items-center"
            }
          >
            <div className={""}>
              <h1 className={"text-2xl font-semibold"}>Creative Toolkit</h1>
              <p
                className={
                  "text-sm lg:text-base text-thememutedforeground mt-2"
                }
              >
                Tools and resources to nail your next creative and plan a
                successful campaign.
              </p>
            </div>
          </div>
        </Stack>
      </Stack>
      <div>
        <div className={"grid lg:grid-cols-3 gap-4"}>
          <div
            style={{
              backgroundImage: `url(/dashboard/t1.jpg)`,
            }}
            className={
              "cursor-pointer hover:border-themedestructive border-2 rounded-lg py-6 px-4 min-h-[20rem] lg:min-h-[40rem] bg-center bg-no-repeat bg-cover"
            }
            onClick={() =>
              navigate({ to: "/feeds/creative-toolkit/copy-grader" })
            }
          >
            <div>
              <div className={"flex gap-2 justify-between"}>
                <p className={"text-xl font-medium"}>Copy Grader</p>
                <Badge variant={"secondaryDestructive"}>New</Badge>
              </div>
              <p className={"mt-1.5 leading-relaxed"}>
                See the readability score of your copy based on the
                Flesch–Kincaid Grade Level.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(/dashboard/t2.jpg)`,
            }}
            className={
              "border-2 rounded-lg py-6 px-4 min-h-[20rem] lg:min-h-[40rem] bg-center bg-cover bg-no-repeat"
            }
          >
            <div>
              <div className={"flex gap-2 justify-between"}>
                <p className={"text-xl font-medium text-thememutedforeground"}>
                  Ad-Test Calculator
                </p>
                <Badge>Coming Soon</Badge>
              </div>
              <p className={"mt-1.5 text-thememutedforeground leading-relaxed"}>
                Calculate the optimal creative testing volume and budget
                allocation.
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(/dashboard/t3.jpg)`,
            }}
            className={
              "border-2 rounded-lg py-6 px-4 min-h-[20rem] lg:min-h-[40rem] bg-center bg-cover bg-no-repeat"
            }
          >
            <div>
              <div className={"flex gap-2 justify-between"}>
                <p className={"text-xl font-medium text-thememutedforeground"}>
                  Creative Analysis
                </p>
                <Badge>Coming Soon</Badge>
              </div>
              <p className={"mt-1.5 text-thememutedforeground leading-relaxed"}>
                Get AI-powered analysis of what’s working and not working for
                your creative assets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Stack>
  );
}
