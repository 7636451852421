import { CanvaLogo, FigmaLogo } from "@/assets/template-logo.tsx";
import { AdHeader } from "@/components/custom-components/AdHeader";
import { Ad } from "@/components/templates/DiscoverGridView";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import { trpc } from "@/utils/trpc.ts";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import { AddToBoard } from "@/components/custom-components/AddToBoard/index.tsx";
import { DotsLoader } from "@/components/custom-components/Loader";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useInView } from "react-intersection-observer";
import { AirTableAdRecord } from "../../../../../shared/airtable";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { Download, Layout, Link, Lock } from "@mynaui/icons-react";
import AdInspirationDetails from "@/components/AdInspirationDetails.tsx";
import { TemplatesFooter } from "@/components/custom-components/TemplatesHeader/index.tsx";
import TemplateDetails from "@/components/TemplateDetails.tsx";
import { Badge } from "@/components/shadcn/badge.tsx";
import { handleDownloadImage } from "@/utils/templates.ts";

export interface AdDataProps {
  brandName?: string;
  avatar?: string;
  Ad: AirTableAdRecord;
  IsPublic: boolean; // If the ad is being viewed from a public user
  IsLocked: boolean; // If the ad is public, all but the requested one should be blurred
  IsShared?: boolean; // If the ad is public, all but the requested one should be blurred
  DesiredWidth: number;
  shouldInvalidateCache: boolean; // We don't want to perform a refetch when we're on the saved page
}

export interface AdCardProps extends ComponentPropsWithoutRef<"div"> {
  adData: AdDataProps;
  showTemplateButton?: boolean;
  showRecentButton?: boolean;
  openDetailsOnInit?: boolean;
  onClickLearnMore?: () => void; // for only ad promos
}

export interface DiscoverAdCardProps extends ComponentPropsWithoutRef<"div"> {
  adData: Ad;
  DesiredWidth: number;
  IsPublic: boolean;
  IsRequested?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
  IsLocked: boolean;
  onView: (postId: number) => void;
}

export const AdCard = forwardRef<HTMLDivElement, AdCardProps>(
  (
    {
      adData,
      showTemplateButton = false,
      showRecentButton = false,
      openDetailsOnInit = false,
      ...props
    },
    ref,
  ) => {
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const [showTemplate, setShowTemplate] = useState<boolean>(true);
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [height, setHeight] = useState<number | undefined>(undefined);

    const [, copyToClipboard] = useCopyToClipboard();

    // We know the desired width of the column but not the height, so we calculate that here
    useEffect(() => {
      if (!adData.Ad["Example Image"][0].height || !adData.DesiredWidth) {
        return;
      }
      const multiplier =
        adData.DesiredWidth / adData.Ad["Example Image"][0].width -
        adData.DesiredWidth;
      const newHeight = adData.Ad["Example Image"][0].height * multiplier;
      setHeight(() => newHeight);
    }, [adData.DesiredWidth]);

    if (!height) {
      return <></>;
    }

    return (
      <div
        ref={ref}
        onMouseEnter={
          showTemplateButton || showRecentButton
            ? () => {
                setShowTemplate(false);
              }
            : () => {}
        }
        onMouseLeave={
          showTemplateButton || showRecentButton
            ? () => {
                setShowTemplate(true);
              }
            : () => {}
        }
        className={`relative rounded bg-[#F5F5F5] break-inside-avoid-column
        ${adData.IsLocked && "pointer-events-none"}
        `}
        {...props}
      >
        {detailsShow && adData && (
          <TemplateDetails
            data={adData}
            open={detailsShow}
            onCloseDrawer={() => setDetailsShow(false)}
          />
        )}
        {adData.IsLocked && !adData.IsShared && <BlurredOverlay />}
        {adData.IsShared && <SharedOverlay />}
        {(showTemplateButton || showRecentButton) && showTemplate && (
          <div
            className={
              "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch transition-opacity duration-300"
            }
          >
            <div className="flex justify-between p-3">
              {showTemplateButton ? (
                <Badge variant={"destructivePurple"} className="gap-1">
                  <Layout className="w-4 h-4" />
                  Template
                </Badge>
              ) : showRecentButton ? (
                <Badge
                  variant={"destructivePurple"}
                  className="gap-1 bg-[#0F172A66]"
                >
                  <div className="w-[0.333rem] h-[0.333rem] rounded-full bg-green-400" />
                  Just Added
                </Badge>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <div
          className={
            "absolute bg-opacity-40 inset-x-0 inset-y-0 bg-slate-900 rounded justify-between opacity-0 hover:opacity-100 flex flex-col items-stretch transition-opacity duration-300 cursor-pointer"
          }
        >
          <div className="flex justify-between items-center p-3">
            <GetAdTemplate Ad={adData.Ad} IsPublic={adData.IsPublic} />
            <div className="flex gap-2">
              {!adData.IsPublic && adData && (
                <ManuallySaveTemplateToBoard
                  open={saveOpen}
                  onOpenChange={setSaveOpen}
                  data={adData}
                  variant="secondary"
                />
              )}
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant={"secondary"}
                      size={"icon"}
                      onClick={() =>
                        handleDownloadImage({
                          imageUrl: adData.Ad["Example Image"][0].url,
                          fileName: adData.Ad["Example Image"][0].filename,
                        })
                      }
                    >
                      <Download className="w-5 h-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Download as image</p>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={() => {
                        copyToClipboard(
                          `${window.location.origin}/share/templates/${adData.Ad.atID}`,
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      size={"icon"}
                      variant={"secondary"}
                    >
                      <Link className="w-5 h-5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Share a public link</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
          <div
            className="h-full w-full bg-slate-900 bg-opacity-0"
            onClick={() => setDetailsShow(true)}
          />
          <div className="flex justify-between p-3">
            <TemplatesFooter
              footerTitle="Cloneable Template"
              footerType="Template"
              titleColor="text-popover"
            />
          </div>
        </div>
        <div className={`rounded min-h-56`}>
          <img
            alt={"ad display"}
            data-original-width={adData.Ad["Example Image"][0].width}
            data-original-height={adData.Ad["Example Image"][0].height}
            loading={"lazy"}
            src={adData.Ad["Example Image"][0].url}
            className={"w-full rounded-md min-h-30"}
          />
        </div>
      </div>
    );
  },
);

export const BlurredOverlay = () => (
  <div
    className={
      "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch transition-opacity duration-300 bg-slate-900 bg-opacity-50"
    }
  >
    <div className="flex justify-between p-3">
      <Badge variant={"destructivePurple"} className="gap-1 bg-themeprimary">
        {/* <div className="w-[0.333rem] h-[0.333rem] rounded-full bg-green-400" /> */}
        <Lock className="h-4 w-4" />
        Locked
      </Badge>
    </div>
  </div>
);
export const SharedOverlay = () => (
  <div
    className={
      "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch"
    }
  >
    <div className="flex justify-between p-3">
      <Badge
        variant={"destructivePurple"}
        className="gap-1 bg-themeprimary bg-opacity-40"
      >
        <div className="w-[0.333rem] h-[0.333rem] rounded-full bg-green-400" />
        Shared With You
      </Badge>
    </div>
  </div>
);

export const DiscoverAdCard = forwardRef<HTMLDivElement, DiscoverAdCardProps>(
  (
    {
      adData,
      DesiredWidth,
      openDetailsOnInit = false,
      IsLocked,
      IsShared,
      IsPublic,
      IsRequested,
      onView,
      ...props
    },
    ref,
  ) => {
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false); // State to manage video play/pause
    const videoRef = useRef<HTMLVideoElement>(null); // Ref for video element

    const [, copyToClipboard] = useCopyToClipboard();

    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [imageRef, inView] = useInView({
      threshold: 0.5, // Trigger when 50% of the image is visible
    });
    const postId = adData.id;

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    useEffect(() => {
      // Record impression only if the image is loaded and the post is in view
      if (inView && imageLoaded) {
        onView(postId); // Notify parent component to add post ID to batch
      }
    }, [inView, imageLoaded, postId, onView]);

    // Manage video playback based on state
    useEffect(() => {
      if (videoRef.current) {
        if (isVideoPlaying) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    }, [isVideoPlaying]);

    const { mutateAsync: requestAdTemplate } =
      trpc.requestAdTemplateRpc.useMutation();

    if (!DesiredWidth) {
      return <></>;
    }

    return (
      <div
        ref={ref}
        className={`cursor-pointer relative rounded-md bg-[#F5F5F5] break-inside-avoid-column
  ${IsLocked && "pointer-events-none"}`}
        {...props}
      >
        {/* Background image or video */}
        <div className="relative w-full min-h-56">
          {adData.imageUrl && (
            <img
              ref={imageRef}
              className="rounded-md w-full"
              alt="ad display"
              loading="lazy"
              src={adData.imageUrl}
              onLoad={handleImageLoad}
            />
          )}
          {adData.videoUrl && (
            <video
              ref={videoRef} // Reference the video element
              className="rounded-md w-full z-10"
              muted
              loop
              controls
              playsInline
              src={adData.videoUrl}
            />
          )}
          {IsShared && !isVideoPlaying && <SharedOverlay />}

          {/* Hover overlay */}
          <div
            className="rounded-md p-3 absolute top-0 left-0 w-full h-full flex flex-col justify-between bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100"
            onMouseEnter={() => setIsVideoPlaying(true)} // Start playing video when mouse hovers over the overlay
            onMouseLeave={() => setIsVideoPlaying(false)} // Stop playing video when mouse leaves the overlay
          >
            {detailsShow && adData && (
              <AdInspirationDetails
                data={adData}
                open={detailsShow}
                IsPublic={IsPublic}
                onOpenChange={() => setDetailsShow(false)}
              />
            )}
            <div className={"flex justify-between gap-5"}>
              <div>
                {!(adData.videoUrl || IsRequested) && (
                  <Button
                    onClick={() => {
                      setAdRequested(true);
                      requestAdTemplate({
                        adId: adData.id,
                      }).then(() => {
                        showToastNotification("success", {
                          message: `Ad Template has been requested!`,
                        });
                        setTimeout(() => {
                          setAdRequested(false);
                        }, 5000);
                      });
                    }}
                    variant={"outline"}
                  >
                    {adRequested ? "Requested!" : "Request"}
                  </Button>
                )}
              </div>
              <div className={"flex gap-2"}>
                {!IsPublic && adData && (
                  <ManuallySaveAdToBoard
                    open={saveOpen}
                    onOpenChange={setSaveOpen}
                    data={adData}
                    variant={"secondary"}
                  />
                )}

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => {
                          copyToClipboard(
                            `${window.location.origin}/share/inspiration/${adData.id}`,
                          );
                          showToastNotification("success", {
                            message: "Copied!",
                          });
                        }}
                        variant={"secondary"}
                        size={"icon"}
                      >
                        <Link className="w-5 h-5" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Share a public link</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            <div
              className={"flex flex-col flex-1"}
              onClick={() => setDetailsShow(true)}
            ></div>
            <div className="text-white flex justify-between">
              {adData.brandId && (
                <AdHeader
                  brandName={adData.brandName}
                  brandId={adData.brandId}
                  Created={adData.earliestView}
                  avatar={adData.brandImage}
                  adRunningDays={
                    adData.isActiveSinceLastScrape
                      ? adData.adRunningDays
                      : adData.inactiveTime
                  }
                  status={
                    adData.isActiveSinceLastScrape ? "active" : "inactive"
                  }
                />
              )}
              <div></div>
            </div>
          </div>
        </div>
        {IsLocked && !IsShared && <BlurredOverlay />}
      </div>
    );
  },
);

type templateProps = {
  Ad: AirTableAdRecord;
  IsPublic?: boolean;
  buttonVersion?: boolean;
};

export const GetAdTemplate = (props: templateProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.CreativeID.toString(),
      type: "record",
    },
    {
      enabled: false, // isRequested,
    },
  );
  const { mutateAsync: captureAdTemplatesDownload, isPending } =
    trpc.captureAdTemplatesDownload.useMutation();

  async function CaptureCanvaDownload() {
    try {
      await captureAdTemplatesDownload({ templateId: props.Ad["Creative ID"] });
    } catch (error) {
      console.error(error);
    }
  }

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setDialogOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      showToastNotification("success", {
        message: "Successfully copied template. CMD + V in Figma",
      });
    }
  };

  return (
    <>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste template for this ad?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access to this ad and hundreds
              more
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {props.buttonVersion ? (
        <div className="flex gap-3">
          <Button
            variant={"secondary"}
            className="w-full"
            onClick={handleCopyClick}
          >
            {isLoading ? (
              <div>
                <DotsLoader />
              </div>
            ) : (
              <div className="flex gap-2">
                <span>Copy to Figma</span> <FigmaLogo height="20" />
              </div>
            )}
          </Button>
          <a
            href={props.IsPublic ? "" : props.Ad["Canva Template LInk"]}
            target={"_blank"}
            onClick={async (e) => {
              e.preventDefault();
              await CaptureCanvaDownload();
              if (!props.IsPublic && props.Ad["Canva Template LInk"]) {
                window.open(props.Ad["Canva Template LInk"], "_blank");
              } else {
                setDialogOpen(true);
                return;
              }
            }}
            className="w-full"
          >
            <Button variant={"secondary"} className="w-full">
              {isPending ? (
                <div>
                  <DotsLoader />
                </div>
              ) : (
                <div className="flex gap-2">
                  <span>Edit in Canva</span>
                  <CanvaLogo height="20" width="20" />
                </div>
              )}
            </Button>
          </a>
        </div>
      ) : (
        <div className={" text-nowrap"}>
          <div className={"flex items-center gap-3"}>
            <div className={"flex gap-2 items-center"}>
              <a
                href={props.IsPublic ? "" : props.Ad["Canva Template LInk"]}
                onClick={async (e) => {
                  e.preventDefault();
                  await CaptureCanvaDownload();

                  if (!props.IsPublic && props.Ad["Canva Template LInk"]) {
                    window.open(props.Ad["Canva Template LInk"], "_blank");
                  } else {
                    setDialogOpen(true);
                    return;
                  }
                }}
                target={"_blank"}
              >
                {isPending ? (
                  <div>
                    <DotsLoader />
                  </div>
                ) : (
                  <CanvaLogo />
                )}
              </a>
              {isLoading ? (
                <div>
                  <DotsLoader />
                </div>
              ) : (
                <div
                  className="w-9 h-9 rounded-full bg-black flex items-center justify-center cursor-pointer"
                  onClick={handleCopyClick}
                >
                  <FigmaLogo />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// This is component is used for the saving airtable ad to the master saved ads

export function ManuallySaveTemplateToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: AdDataProps;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="template"
      templateData={{
        Ad: {
          CreativeID: data.Ad.CreativeID,
          atID: data.Ad.atID,
          AdDbId: data.Ad.AdDbId,
        },
      }}
      initialSavedState={data.Ad.Liked}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}

// This is component is used for the saving ads in db to the master saved ads

export function ManuallySaveAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: Ad;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="ad"
      adData={{ id: data.id }}
      initialSavedState={data.isSaved}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}
